import { CommandFactory } from "@/cadplugins/map/commandfactory";
import { LTDwgServers } from "@/cadplugins/cad/ltdwgserver.js";
import { mapGetters } from "vuex";
import { layerMixin } from "@/utils/cadMinxins/layerMixin"; //图层
import { attributeMixin } from "@/utils/cadMinxins/attributeMixin"; //属性
import { setMixin } from "@/utils/cadMinxins/setMixin"; //设置方法
import { blockMixin } from "@/utils/cadMinxins/blockMixin"; //块
import { otherMixin } from "@/utils/cadMinxins/otherMixin"; //其他
import { CustomCommandManager } from "@/business/sprinkler/customcommandmanager";
import { ConfigManager } from "@/business/rule/configManager.js";
import { get, post } from "@/api/index";
import { dwg_bylayer } from "../cadplugins/cad/ltentity";
export const toolMixin = {
  mixins: [setMixin, blockMixin, attributeMixin, layerMixin, otherMixin],
  data() {
    return {
      // 画具参数-start
      typeIndex: -1,
      typeList: [
        {
          typeName: "tcgl",
          name: "图层管理",
          sname: "图层",
          dataName: "attributeData",
          fn: "layerManagement",
          isSelected: false
        },
        {
          typeName: "sxmb",
          name: "属性面板",
          sname: "属性",
          dataName: "layerData",
          fn: "attrManagement",
          isSelected: false
        },
        {
          typeName: "htgj",
          name: "绘图工具",
          isShow: true,
          list: [
            {
              typeName: "line",
              type: "line",
              name: "直线",
              fn: "line"
            },
            {
              typeName: "pline",
              type: "pline",
              name: "多段线",
              fn: "pline"
            },
            {
              typeName: "circle",
              type: "circle",
              name: "圆",
              fn: "circle"
            },
            {
              typeName: "arc",
              type: "arc",
              name: "弧",
              fn: "arc"
            },
            {
              typeName: "rectangle",
              type: "rectangle",
              name: "矩形",
              fn: "rectangle"
            },
            {
              typeName: "ellipse",
              type: "ellipse",
              name: "椭圆",
              fn: "ellipse"
            },
            {
              typeName: "ellipsearc",
              type: "ellipsearc",
              name: "椭圆弧",
              fn: "ellipsearc"
            },
            {
              typeName: "spline",
              type: "spline",
              name: "样条曲线",
              fn: "spline"
            },
            {
              typeName: "jx",
              type: "hatch",
              name: "填充",
              fn: "hatch"
            },
            {
              typeName: "polygon",
              type: "polygon",
              name: "多边形",
              fn: "polygon"
            },
            {
              typeName: "alignDimension",
              type: "alignDimension",
              name: "对齐标注",
              fn: "alignDimension"
            },
            {
              typeName: "rotateDimension",
              type: "rotateDimension",
              name: "线性标注",
              fn: "rotateDimension"
            },
            {
              typeName: "angularDimension",
              type: "angularDimension",
              name: "角度标注",
              fn: "angularDimension"
            },
            {
              typeName: "text",
              type: "text",
              name: "文本",
              fn: ""
            }
          ],
          fn: "",
          isSelected: false
        },
        {
          typeName: "bjgj",
          name: "编辑工具",
          isShow: true,
          list: [
            {
              typeName: "delete",
              type: "delete",
              name: "删除",
              fn: "delete"
            },
            {
              typeName: "move",
              type: "move",
              name: "移动",
              fn: "move"
            },
            {
              typeName: "rotate",
              type: "rotate",
              name: "旋转",
              fn: "rotate"
            },
            {
              typeName: "scale",
              type: "scale",
              name: "缩放",
              fn: "scale"
            },
            {
              typeName: "copy", //拷贝
              type: "copy",
              name: "拷贝",
              fn: "copy"
            },
            {
              typeName: "trim", //裁剪
              type: "trim",
              name: "裁剪",
              fn: "trim"
            },
            {
              typeName: "extend", //extend
              type: "extend",
              name: "延伸",
              fn: "extend"
            },
            {
              typeName: "join", //合并
              name: "合并",
              type: "join",
              fn: "join"
            },
            {
              typeName: "mirror", //镜像
              type: "mirror",
              name: "镜像",
              fn: "mirror"
            },
            {
              typeName: "break", //打断
              name: "打断",
              type: "break",
              fn: "break"
            },
            {
              typeName: "explode", //炸开
              name: "块炸开",
              type: "explode",
              fn: "explode"
            },
            {
              typeName: "qselect", //快速选择
              name: "快速选择",
              type: "qselect",
              fn: "qselect"
            },
            {
              typeName: "jx",
              type: "arrayrect",
              name: "矩形阵列",
              fn: "arrayrect"
            }
          ],
          fn: "",
          isSelected: false
        },
        // {
        //   typeName: "tzpz",
        //   name: "图纸批注",
        //   fn: ""
        // },
        // {
        //   typeName: "cl",
        //   name: "测量",
        //   fn: ""
        // },
        {
          typeName: "dAdd",
          name: "创建块",
          fn: ""
        },
        {
          typeName: "dEdit",
          name: "插入块",
          fn: ""
        }
      ],
      isShowMouseBox: false,
      mouseTop: 0,
      mouseLeft: 0,
      rightClickList: [
        {
          name: "绘制主管道(A)",
          fn: "drawGd"
        },
        {
          name: "返回上一步(B)",
          fn: "goBack"
        },
        {
          name: "取消(C)",
          fn: "cancleMouse"
        },
        {
          name: "确定(D)",
          fn: "sureMouse"
        }
      ],
      isShowEdit: false,
      // 测量
      measureType: "",
      measureList: [
        {
          name: "测量距离",
          type: "distance"
        },
        {
          name: "测量面积",
          type: "area"
        },
        {
          name: "测量角度",
          type: "angle"
        },
        {
          name: "测量坐标",
          type: "coordinate"
        },
        {
          name: "取消测量",
          type: "cancel"
        }
      ],
      editList: [],
      cardIndex: 0,
      cardColor: "",
      cardList: [],
      showDialog: false,
      // 判断是绘图工具还是编辑工具
      isDrawName: "",
      // 画具参数-end
      // 底部栏目数据
      isShowBLm: false,
      bLmData: {
        bfTxt: "",
        btnList: [],
        afTxt: "",
        oldVal: "",
        value: ""
      },
      isOnShowEdit: false,
      // 设置栏数据
      isFull: false,
      statusbarData: [
        {
          id: "statusbar00",
          type: "objectSnap",
          name: "对象捕捉",
          isSelect: true
        },
        {
          id: "statusbar01",
          type: "orthogonal",
          name: "正交",
          isSelect: false
        },
        {
          id: "statusbar02",
          type: "background",
          name: "背景颜色",
          isSelect: false
        },
        {
          id: "statusbar03",
          type: "screen",
          name: "全屏",
          isSelect: false
        },
        {
          id: "statusbar04",
          type: "setting",
          name: "设置",
          isSelect: false
        }
      ],
      navigationIndex: -1,
      navigationList: [
        {
          typeName: "pdf",
          type: "pdf",
          name: "批量打印PDF",
          fn: "exportPDF"
        },
        {
          typeName: "zdyxx",
          type: "zdyxx",
          name: "自定义线型",
          fn: "showCustomLine"
        },
        {
          typeName: "order",
          type: "order",
          name: "自定义命令",
          fn: "showOrderSet"
        },
        {
          typeName: "plhz",
          type: "line",
          name: "自动喷水灭火系统",
          fn: "onShowSpray",
          list: [
            {
              typeName: "plhz",
              type: "line",
              name: "喷淋配置",
              fn: "onShowSpray"
            },
            {
              typeName: "plsj",
              type: "pline",
              name: "喷淋系统图",
              fn: "designSprinkler"
            },
            {
              typeName: "pljs",
              type: "circle",
              name: "喷淋计算",
              fn: "countSprinkler"
            },
            {
              typeName: "clearSpire",
              type: "clearSpire",
              name: "清除自动绘制的喷淋管道",
              fn: "clearSpire"
            },
            {
              typeName: "xttgx",
              type: "xttgx",
              name: "系统图联动更新",
              fn: "linkageUpdate"
            },
            {
              typeName: "ptbs",
              type: "ptbs",
              name: "普通喷淋布设",
              fn: "showPtbs"
            },
            {
              typeName: "pent",
              type: "pent",
              name: "货架喷淋布设",
              fn: "showGoodsSet"
            }
          ]
        }
      ],
      // 颜色选择
      colorVal: "",
      selectColorVal: "",
      showTypeColor: false,
      colorIndex: 0,
      colorType: "",
      descData: {
        val: "",
        value: "",
        index: ""
      },
      // 快速选择列表数据
      ksLayers: [],
      ksLines: [],
      ksLineWidths: [],
      blockNames: [],
      noSelectList: ["text", "rotate", "theight"],
      styleList: [], //文本样式
      bzStyleList: [] //标注样式
    };
  },
  computed: {
    ...mapGetters(["packData", "userInfo", "isStaging"]),
    isPtUser() {
      if (
        this.packData &&
        this.packData.packageSimple &&
        this.packData.packageSimple.packageType &&
        this.packData.packageSimple.packageType == "FREE"
      )
        return true;
      else return false;
    },
    // 画具参数-start
    // 显示图层|属性
    isShowLayer() {
      return this.cardList.length > 0 && this.isDrawName == "图层";
    },
    // 栏目显示数据
    cardDatas() {
      if (this.cardList.length > 0 && this.isDrawName == "图层")
        return this.layerData;
      else return this.attributeData;
    }
    // 画具参数-end
  },
  methods: {
    // 鼠标右击事件
    get_key_list(button_list) {
      let key_list = [];
      for (let value of button_list) {
        let key_regex = /\((.+?)\)/g; // 小括号
        let match_keystr = value.match(key_regex);
        if (match_keystr == null || match_keystr.length == 0) {
          continue;
        }
        let sub_key = match_keystr[0].replace("(", "").replace(")", "");
        key_list.push(sub_key.toUpperCase());
      }
      return key_list;
    },
    showMouse(x, y, list) {
      if (list.length == 0) {
        return;
      }
      this.mouseTop = 0;
      this.mouseLeft = 0;
      if (x && y) {
        this.mouseTop = y + "px";
        this.mouseLeft = x + "px";
      }

      this.rightClickList = [];
      let key_list = this.get_key_list(list);
      for (let i = 0; i < list.length; i++) {
        this.rightClickList.push({ name: list[i], fn: key_list[i] });
      }
      this.isShowMouseBox = true;
    },
    onGbMouse() {
      this.isShowMouseBox = false;
    },
    mouseRightClick(fn) {
      CommandFactory.getInstance().putString(this.pixiMap, fn);
      this.onGbMouse();
    },
    // 绘制管道
    drawGd() {
      console.log("drawGd");
    },
    getBlockById(id) {
      get(this.$setApi("/cus/design-lib/block/") + id).then(res => {
        res;
      });
    },
    onEsc(e) {
      // if (e.keyCode === 27) {
      //   //执行你要做的事情
      //   this.pixiMap.escape();
      // }
    },
    // 重置数据
    resetData() {
      this.isShowEdit = false;
      this.cardIndex = 0;
      this.isAddBlock = true;
      this.showText = false;
      this.isDrawName = "";
      this.onSelection = false;
      this.initSpray = false;
      this.showSpray = false;
      this.showRelation = false;
      this.isShowBLm = false;
      this.cardList = [];
      this.cancelColor();
      this.cancelDialog();
    },
    // 导航条方法
    selectCadType(idx) {
      const selectItem = this.typeList[idx];
      if (this.isFunction(this[selectItem.fn])) {
        this[selectItem.fn](selectItem);
      } else if (this.isString(this[selectItem.fn])) {
        //执行命令;
        CommandFactory.getInstance().execCommand(
          this[selectItem.fn],
          this.pixiMap,
          this.blockForm
        );
      } else if (selectItem.name.indexOf("工具") > -1) {
        selectItem.isSelected = !selectItem.isSelected;
        let index = -1;
        this.typeList.some((ele, i) => {
          if (ele.name.indexOf("工具") > -1 && i != idx) {
            index = i;
            return true;
          }
        });
        this.typeList[index].isSelected = false;
        if (selectItem.isSelected) {
          this.typeIndex = idx;
          this.showDialog = false;
          this.editList = selectItem.list || [];
          this.isShowEdit = true;
          this.changeMapSize();
        } else {
          this.typeIndex = -1;
          this.editList = [];
          this.isShowEdit = false;
        }
      } else {
        if (selectItem.name == "创建块") {
          // CommandFactory.getInstance().execCommand("addblock", this.pixiMap);
          this.onShowAddBlock();
        } else if (selectItem.name == "插入块") {
          this.onShowBlock();
        } else {
          this.showDialog = false;
        }
      }
    },
    setTypeVal(name, val = false) {
      let index = -1;
      this.typeList.some((item, idx) => {
        if (item.sname == name) {
          index = idx;
          return true;
        }
      });
      if (index > -1) this.typeList[index].isSelected = val;
    },
    returnCardIndex(name) {
      let index = -1;
      this.cardList.some((item, idx) => {
        if (item.name == name) {
          index = idx;
          return true;
        }
      });
      return index;
    },
    // 测量方法
    handleMeasure(val) {
      console.log("handleMeasure", val);
    },
    // 属性和图层栏目切换
    selectCardType(name, idx) {
      this.cardIndex = idx;
      this.isDrawName = name;
      this.typeList.map(item => {
        if (item.sname == name) {
          item.isSelected = true;
        } else item.isSelected = false;
        return item;
      });
    },
    // 删除栏目
    delCard(idx) {
      if (idx == 1) this.cardIndex--;
      this.setTypeVal(this.cardList[idx].name);
      this.cardList.splice(idx, 1);
      this.changeMapSize();
    },
    // 取消全部颜色选择框
    cancelColor() {
      this.showColor = false;
      this.showJhColor = false;
      this.selectionColor = false;
    },
    // 唤起快速选择
    showSelection() {
      this.$refs.ksxzItem.showDialog();
    },

    // 底部栏目-start
    showBlm(str = "") {
      this.isShowBLm = true;
      // this.$nextTick(() => {
      //   this.$refs.plsj && this.$refs.plsj.focus();
      // });
      let regex = /\[(.+?)\]/g; // 中括号
      let regex1 = /\<(.+?)\>/g; // 尖括号
      this.bLmData = {
        bfTxt: "",
        btnList: [],
        afTxt: "",
        value: ""
      };
      if (str.indexOf(":") - 1 > 0) {
        this.bLmData.bfTxt = str.substring(0, str.indexOf(":") + 1);
      }

      if (str.lastIndexOf("[") - 1 > 0) {
        this.bLmData.bfTxt = str.substring(0, str.lastIndexOf("["));
      }

      if (str.lastIndexOf("]") > 0) {
        this.bLmData.afTxt = str.substring(
          str.lastIndexOf("]") + 1,
          str.length
        );
      }

      let sub_match_str = str.match(regex1);
      if (sub_match_str && sub_match_str.length > 0) {
        this.bLmData.value = sub_match_str[0].replace("<", "").replace(">", "");
      }

      this.bLmData.oldVal = JSON.parse(JSON.stringify(this.bLmData.value));
      this.bLmData.value = "";
      if (str.match(regex) && str.match(regex)[0])
        this.bLmData.btnList = `${str
          .match(regex)[0]
          .replace("[", "")
          .replace("]", "")}`.split(" ");
    },
    clickBlmBtm(value) {
      let regexs = /\((.+?)\)/g; // 小括号
      let key = `${value
        .match(regexs)[0]
        .replace("(", "")
        .replace(")", "")}`;
      CommandFactory.getInstance().putString(this.pixiMap, key);
      // this.pixiMap.onKeyDown({ key: key });
    },
    sureBlm() {
      let command = CommandFactory.getInstance().currentCommand;
      if (command !== null) {
        let value = this.bLmData.value || this.bLmData.oldVal;
        CommandFactory.getInstance().putString(this.pixiMap, value);
      } else {
        let command_name = CustomCommandManager.getInstance().findCommandBySimpleName(
          this.bLmData.value
        );
        CommandFactory.getInstance().execCommand(command_name, this.pixiMap);
      }

      // let key = `${value
      //   .match(regexs)[0]
      //   .replace("(", "")
      //   .replace(")", "")}`;
      // this.pixiMap.onKeyDown({ key: key });
    },
    // 底部栏目-end
    // 系统设置栏-start
    selectStatus(item) {
      item.isSelect = !item.isSelect;
      // objectSnap:对象捕捉 orthogonal:正交 background:背景色 screen:全屏 setting:设置
      if (item.type) {
        if (item.type == "screen") {
          this.toFullOrExit();
        } else if (item.type == "objectSnap") {
          //设置捕捉;
          this.pixiMap.snap.snapEnable = item.isSelect;
        } else if (item.type == "orthogonal") {
          //设置正交 ;
          this.pixiMap.snap.orthogonalEnable = item.isSelect;
        } else if (item.type == "background") {
          //设置背景色;
          // this.pixiMap.backgroundColor = 0x00ff00;
          // this.onShowBgColor("background");
          this.$refs.dialogColor.showDialog(
            this.pixiMap.backgroundColor,
            this.pixiMap,
            "background"
          );
        } else if (item.type == "setting") {
          //设置;
          this.onShowSet(this.pixiMap);
        }
      }
    },
    requestFullScreen() {
      let de = document.documentElement;
      if (de.requestFullscreen) {
        de.requestFullscreen();
      } else if (de.mozRequestFullScreen) {
        de.mozRequestFullScreen();
      } else if (de.webkitRequestFullScreen) {
        de.webkitRequestFullScreen();
      }
    },
    exitFullscreen() {
      let de = document;
      if (de.exitFullscreen) {
        de.exitFullscreen();
      } else if (de.mozCancelFullScreen) {
        de.mozCancelFullScreen();
      } else if (de.webkitCancelFullScreen) {
        de.webkitCancelFullScreen();
      }
    },
    toFullOrExit() {
      this.isFull = !this.isFull;
      if (this.isFull) {
        this.requestFullScreen();
      } else {
        this.exitFullscreen();
      }
    },
    // 系统设置栏-end
    // 返回颜色值
    returnColorVal(val, geometry) {
      let value = val;
      if (this.returnLayerName(val)) value = geometry.entityColor(this.pixiMap);
      return value.replace(/0x/gi, "#");
    },
    // 判断是否是byLayer
    returnLayerName(val) {
      return val.indexOf("ayer") > -1;
    },
    // 自定义线型
    showCustomLine() {
      this.$refs.lineVue.onShowLine(this.pixiMap);
    },
    // 自定义命令
    showOrderSet() {
      this.getUserSettingData().then(res => {
        if (res && res.customcommand) {
          CustomCommandManager.getInstance().commandList = JSON.parse(
            res.customcommand
          );
        }
        let command_list = CustomCommandManager.getInstance().commandList;

        this.$refs.orderVue.onShowDialog(command_list);
      });
    },
    getColorVal(_colorInfo) {
      let datas = {
        value: "",
        val: "",
        index: -1
      };
      if (_colorInfo) {
        let index = Number(_colorInfo.index);
        let colorIndex = -1;

        if (
          (index < 0 || index > 255) &&
          this.returnLayerName(_colorInfo.color)
        ) {
          datas.value = "#fff";
          datas.val = dwg_bylayer;
        } else {
          if (_colorInfo.aci) {
            datas.index = index;
            colorIndex = window.colorIndexs.indexOf(index);
            if (colorIndex > -1) {
              datas.value = window.commonColor[colorIndex].color;
              datas.val = window.commonColor[colorIndex].name;
            } else {
              window.colorLists.some(it => {
                if (it.index == Number(_colorInfo.index)) {
                  datas.value = this.Rgb2Hex(it.color);
                  datas.val = "其他";
                  return true;
                }
              });
            }
          } else {
            window.commonColor.some((item, index) => {
              if (item.color == this.Rgb2Hex(_colorInfo.color)) {
                colorIndex = index;
                return true;
              }
            });
            if (colorIndex == -1) {
              window.colorLists.some(it => {
                if (it.color == _colorInfo.color) {
                  datas.value = this.Rgb2Hex(it.color);
                  datas.val = "其他";
                  datas.index = it.index;
                  return true;
                }
              });
            } else {
              datas.value = window.commonColor[colorIndex].color;
              datas.val = window.commonColor[colorIndex].name;
              datas.index = window.commonColor[colorIndex].index;
            }
          }
        }
      }
      return datas;
    },
    getFeatureColorVal(featureDat) {
      let geometry = featureDat._geometry,
        layer = featureDat._layer,
        colorInfo = geometry._colorInfo,
        colorVal = "",
        colorIndex = -1;
      if (geometry._colorInfo.color.indexOf("layer") > -1) {
        colorInfo = layer._colorInfo;
      }
      console.log("getFeatureColorVal", colorInfo);
      if (colorInfo.aci) colorIndex = colorInfo.index;
      else {
        window.colorLists.some((item, i) => {
          if (this.$judgeColor(colorVal, item.color)) {
            colorIndex = i;
            return true;
          }
        });
      }
      if (colorInfo.color.indexOf("layer") > -1) {
        colorIndex = -1;
        colorVal = geometry.entityColor(this.pixiMap);
      } else {
        colorVal = colorInfo.color;
      }

      let colorData = {
        value: colorVal ? this.$rgbTenToSix(colorVal) : "#fff",
        val: colorIndex == -1 ? dwg_bylayer : colorVal,
        index: colorIndex
      };

      return colorData;
    },
    returnColorInfo(index, color = "#fff") {
      let color_info = {
        index: -1,
        color: dwg_bylayer,
        colorVal: color
      };
      if (index == -1) {
        color_info.color = dwg_bylayer;
        color_info.colorVal = color;
      } else {
        index > -1 && index <= 255;
      }
      {
        window.colorLists.some((ele, i) => {
          if (ele.index == index) {
            color_info.index = i;
            color_info.color = ele.color;
            color_info.colorVal = this.$rgbTenToSix(ele.color);
          }
        });
      }
      return color_info;
    },
    isObjectEqual(obj1, obj2) {
      const obj1Keys = Object.keys(obj1);
      const obj2Keys = Object.keys(obj2);

      if (obj1Keys.length !== obj2Keys.length) {
        return false;
      }

      for (let key of obj1Keys) {
        if (obj1[key] !== obj2[key]) {
          return false;
        }
      }

      return true;
    },
    //普通喷淋布设
    showPtbs() {
      this.getProjectData().then(res => {
        if (res && res.spray) {
          let data = JSON.parse(res.spray);
          let sprayConfig = this.getSprayConfig(data);
          if (sprayConfig) {
            sprayConfig.selectLayers = data.selectLayers;
            sprayConfig.distanceToWall = data.distanceToWall;
            sprayConfig.notSetDistanceToWall = data.isSetting || false;
            //调用命令
            CommandFactory.getInstance().execCommand(
              "drawsprinkler",
              this.pixiMap,
              sprayConfig
            );
          }
        } else {
          this.$message.warning("请先设置喷淋配置");
        }
      });
    },
    // 货架喷头设置
    showGoodsSet() {
      this.getProjectData().then(res => {
        if (res && res.spray) {
          let data = JSON.parse(res.spray);
          let sprayConfig = this.getSprayConfig(data);
          if (sprayConfig) {
            //调用命令
            CommandFactory.getInstance().execCommand(
              "drawWarehouse",
              this.pixiMap,
              sprayConfig
            );
          }
        } else {
          this.$message.warning("请先设置喷淋配置");
        }
      });
    },
    //从规则中获取配置信息
    getSprayConfig(data) {
      let sprayConfig = {};
      try {
        sprayConfig = ConfigManager.getInfo(
          data.SprinklerCategory,
          data.IsTallRoom == "是",
          data.BuildingCategory,
          data.IsSetSprayInShelves == "是",
          data.ActionArea,
          data.SprayStrength,
          data.DangerLevel,
          data.CoefficientK,
          data.MinPressure,
          data.MinPressureGz,
          data.SprinklerSettingMethod,
          data.ShelfCoefficientK,
          data.ShelfFloors,
          data.CustomMaxDisBetweenSprinklers,
          data.CustomMinDisBetweenSprinklers,
          data.CustomMaxDisWithWall,
          data.CustomMinDisWithWall
        );
      } catch (e) {
        this.$message.warning("喷淋配置参数异常，具体原因为：" + e.message);
        return null;
      }
      return sprayConfig;
    },
    //获取工程配置信息;
    getProjectData() {
      return new Promise((resolve, reject) => {
        get(this.$setApi("/cad/project/config/") + this.productId)
          .then(res => {
            let data = {};
            if (res && res.data && typeof res.data == "string") {
              data = JSON.parse(res.data);
              if (typeof data == "string") data = JSON.parse(data);
            }
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    // 保存工程配置信息
    saveProjectData(type, data = { sprayConfig: "" }) {
      return new Promise((resolve, reject) => {
        if (this.productId) {
          let postData = {
            projectId: this.productId,
            json: {}
          };
          this.getProjectData()
            .then(res => {
              postData.json = res;
              if (postData.json[type]) postData.json[type] = data;
              else this.$set(postData.json, type, data);
              postData.json = JSON.stringify(postData.json);
              post(this.$setApi("/cad/project/config"), postData)
                .then(res => {
                  if (res && res.data) {
                    resolve();
                  }
                })
                .catch(() => {});
            })
            .catch(() => {
              reject();
            });
        }
      });
    },

    judegBylayer(colorValue) {
      if (!colorValue) return "#fff";
      else if (colorValue.indexOf("layer") > -1) return window.bylayerColor;
      else return colorValue;
    }
  },
  watch: {
    isShowLayer: {
      handler(newVal) {
        if (newVal) {
          this.keyDown();
        }
      },
      immediated: true
    }
  }
};
