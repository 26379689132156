"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DrawUtilImpl = void 0;
const ltentity_1 = require("../cad/ltentity");
const ltfeature_1 = require("../data/ltfeature");
const ltfields_1 = require("../data/ltfields");
//import * as PIXI from 'pixi.js'
class DrawUtilImpl {
    static trackEntity(map, entity) {
        if (entity === null) {
            return;
        }
        //应该创建一个临时层处理，不应该在原图层;
        map.removeTrackGraphics(entity);
        entity.worldDraw(map.canvas, true, null, map.trackGraphics);
    }
    static destroyEntity(map, entity) {
        if (entity === null) {
            return;
        }
        map.removeTrackGraphics(entity);
    }
    static saveEntity(map, entity) {
        let layer = map.activeLayer();
        let feature = new ltfeature_1.LTFeature({
            geometry: entity.clone(),
            layer: layer
        });
        //线
        feature.setValue(ltfields_1.FieldNames.EntityLayer, layer.name);
        feature.setValue(ltfields_1.FieldNames.Linetype, ltentity_1.dwg_bylayer);
        //feature.setValue(FieldNames.EntityType, "AcDbPolyline");
        feature.setValue(ltfields_1.FieldNames.Color, ltentity_1.dwg_bylayer);
        feature.setValue(ltfields_1.FieldNames.Width, 0.0);
        map.addFeature(feature, true);
        return feature;
    }
    //绘制临时的线;
    static drawDashLine(canvas, line) {
        const shader = new PIXI.smooth.DashLineShader({ dash: 5, gap: 5 });
        let any_ent = line;
        let graphics = null;
        if (any_ent._track_graphics == undefined) {
            any_ent._track_graphics = new PIXI.smooth.SmoothGraphics();
            graphics = any_ent._track_graphics;
        }
        else {
            any_ent._track_graphics.clear();
            graphics = any_ent._track_graphics;
        }
        graphics.lineStyle({ width: 1, native: true, color: 0xFEEB77, shader });
        graphics.moveTo(line.getStart().x, line.getStart().y);
        graphics.lineTo(line.getEnd().x, line.getEnd().y);
        graphics.endFill();
        canvas.addGraphics(graphics, canvas.trackGraphics);
    }
    static destroyDashEntity(map, entity) {
        if (entity === null) {
            return;
        }
        let any_ent = entity;
        map.trackGraphics.removeChild(any_ent._track_graphics);
    }
    static highlightEntity(map, entity) {
        //高亮对象,设置高亮的highlight;
        let any_ent = entity;
        if (any_ent._highlight_graphics == undefined) {
            let glow_filter = new PIXI.GlowFilter();
            any_ent._highlight_graphics = new PIXI.Graphics();
            any_ent._highlight_graphics.filters = [glow_filter];
            map.addGraphics(any_ent._highlight_graphics, map.trackGraphics);
        }
        any_ent._highlight_graphics.clear();
        entity.worldDraw(map.canvas, true, null, any_ent._highlight_graphics);
    }
    static cancelhighlightEntity(map, entity) {
        //高亮对象;
        let any_ent = entity;
        if (any_ent._highlight_graphics == undefined) {
            return;
        }
        map.trackGraphics.removeChild(any_ent._highlight_graphics);
        any_ent._highlight_graphics.destroy();
        any_ent._highlight_graphics = undefined;
    }
}
exports.DrawUtilImpl = DrawUtilImpl;
