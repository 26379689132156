"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.drawTextCommand = drawTextCommand;
const commandfactory_1 = require("../map/commandfactory");
const ltentity_js_1 = require("../cad/ltentity.js");
const ltpoint_1 = require("../cad/ltpoint");
const ltfeature_js_1 = require("../data/ltfeature.js");
const ltfields_1 = require("../data/ltfields");
class DrawTextCommandImpl {
    constructor(cadmap, param_map) {
        this._textEntity = null;
        this._map = cadmap;
        this._form = param_map;
        this._textEntity = new ltentity_js_1.LTText(new ltpoint_1.LTPoint(0, 0));
        //界面中的信息;
        this._textEntity.setColor(this._form.color);
        this._textEntity.text = this._form.content;
        this._textEntity.setHeight(this._form.fontSize);
        this._textEntity.setRotate(this._form.angle);
        if (this._form.fontname != undefined) {
            this._textEntity.subData().fontName = this._form.fontname;
        }
        if (this._form.widthFactor != undefined) {
            this._textEntity.subData().widthFactor = this._form.widthFactor;
        }
    }
    destroyEntity() {
        if (this._textEntity != null) {
            this._map.removeTrackGraphics(this._textEntity);
            this._textEntity = null;
        }
    }
    getTextEntity() {
        return this._textEntity;
    }
    trackText(pos) {
        if (this._textEntity === null) {
            return;
        }
        if (this._textEntity != null) {
            this._map.removeTrackGraphics(this._textEntity);
        }
        this._textEntity.setPoint(pos);
        this._textEntity.worldDraw(this._map.canvas, true, null, this._map.trackGraphics);
    }
    saveFeature(map_pos) {
        this.trackText(map_pos);
        //Save
        if (this._textEntity !== null) {
            var fea = new ltfeature_js_1.LTFeature({
                geometry: this._textEntity.clone(),
                layer: this._map.activeLayer()
            });
            fea.setValue(ltfields_1.FieldNames.EntityLayer, this._map.activeLayer().name);
            fea.setValue(ltfields_1.FieldNames.EntityType, "AcDbText");
            fea.setValue(ltfields_1.FieldNames.Color, this._form.color);
            fea.setValue(ltfields_1.FieldNames.Width, this._form.fontSize);
            fea.setValue(ltfields_1.FieldNames.Rotate, this._form.angle);
            this._map.addFeature(fea);
        }
        return commandfactory_1.CommandStatus.eOK;
    }
}
//绘制文字;
function* drawTextCommand(map, param_map) {
    if (param_map == null) {
        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
    }
    let cmd = new DrawTextCommandImpl(map, param_map);
    function drawCallback(mouse) {
        cmd.trackText(mouse.mapPos);
    }
    //let ptext = { tip: "绘制自喷管标注 ",color:"0xff00ff", content: riserName,fontSize:750,angle:0,continue:false,noSaveFeature: true};
    while (true) {
        commandfactory_1.CommandFactory.getInstance().getPoint(map, "指定文字插入点", drawCallback);
        let input_point = yield;
        if (input_point.done == commandfactory_1.CommandStatus.eCancel || input_point.done == commandfactory_1.CommandStatus.eDone) {
            cmd.destroyEntity();
            return { value: null, done: commandfactory_1.CommandStatus.eCancel };
        }
        if (param_map != null && param_map.continue === false) {
            if (param_map.noSaveFeature !== undefined && param_map.noSaveFeature == false) {
                cmd.saveFeature(input_point.value);
                cmd.destroyEntity();
                return { value: null, done: commandfactory_1.CommandStatus.eDone };
            }
            let ent = cmd.getTextEntity();
            cmd.destroyEntity();
            return { value: ent, done: commandfactory_1.CommandStatus.eDone };
        }
        cmd.saveFeature(input_point.value);
    }
    return { value: null, done: commandfactory_1.CommandStatus.eDone };
}
