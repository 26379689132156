"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SprinklerSpacing = void 0;
class SprinklerSpacing {
    compute(width, height, radius, maxDisBetweenSprinklers) {
        //需要满足的所有条件
        //0<p<R (p为X方向上两个喷头的间距/2)
        //0<q<R (q为Y方向上两个喷头的间距/2)
        //2p>=1800
        //2q>=1800
        //p²+q²=L²<=R² (L为p和q组成的直角三角形的斜边)
        //np*2p+2p>=width (np为X方向上的喷头数量)
        //nq*2q+2q>=height (nq为Y方向上的喷头数量)
        //从900开始遍历，一直到radius
        let infos = new Array();
        for (let p = 900; p <= radius && p <= maxDisBetweenSprinklers; p += 50) {
            //q²<=R²-p²
            let q = this.getFloorValueOfMultipleOf50(Math.sqrt(radius * radius - p * p));
            if (q < 900)
                continue;
            this.addInfo(p, q, width, height, infos);
        }
        for (let q = 900; q <= radius && q <= maxDisBetweenSprinklers; q += 50) {
            //q²<=R²-p²
            let p = this.getFloorValueOfMultipleOf50(Math.sqrt(radius * radius - q * q));
            if (p < 900)
                continue;
            this.addInfo(p, q, width, height, infos);
        }
        //计算喷头数最少的方案
        infos.sort(function (a, b) { return a._count - b._count; });
        return [infos[0]._p, infos[0]._q];
    }
    getFloorValueOfMultipleOf50(value) {
        return Math.floor(value / 50) * 50;
    }
    addInfo(p, q, width, height, infos) {
        //np>=width/2p
        let np = Math.ceil(width / (2 * p));
        //nq>=height/2q
        let nq = Math.ceil(height / (2 * q));
        infos.push(new SprinklerSpacingInfo(np, nq, p, q));
    }
}
exports.SprinklerSpacing = SprinklerSpacing;
class SprinklerSpacingInfo {
    constructor(np, nq, p, q) {
        this._np = np;
        this._nq = nq;
        this._p = p;
        this._q = q;
        if (np == 0)
            this._count = nq;
        else if (nq == 0)
            this._count = np;
        else
            this._count = np * nq;
    }
}
