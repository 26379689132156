"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.designGraphcisCommand = designGraphcisCommand;
exports.linkageUpdate = linkageUpdate;
const ltentity_1 = require("../../cadplugins/cad/ltentity");
const ltpoint_1 = require("../../cadplugins/cad/ltpoint");
const ltfeature_1 = require("../../cadplugins/data/ltfeature");
const commandfactory_1 = require("../../cadplugins/map/commandfactory");
const ltentity_2 = require("../../cadplugins/cad/ltentity");
const ltutils_1 = require("../../cadplugins/cad/ltutils");
const sprinklerutils_1 = require("../sprinkler/sprinklerutils");
const drawutils_1 = require("../../cadplugins/tools/drawutils");
//立管、水流、末端试水装置判断;
var FlagType;
(function (FlagType) {
    //立管;
    FlagType[FlagType["FlagRiser"] = 0] = "FlagRiser";
    //水流指示器
    FlagType[FlagType["FlagWater"] = 1] = "FlagWater";
    //末端;
    FlagType[FlagType["FlagTail"] = 2] = "FlagTail";
})(FlagType || (FlagType = {}));
//行列号的序号是从下往上算的，由于必须有普通的对象来绘制，实现相对简单，不支持合并,行列有可能不等宽度;
class LTEntityTable {
    constructor(box, des) {
        this._column_percents = [];
        this._row_percents = [];
        this._box = box;
        this._des = des;
    }
    set columnPercents(column_percents) {
        this._column_percents = column_percents;
    }
    get columnPercents() {
        return this._column_percents;
    }
    set rowPercents(row_percents) {
        this._row_percents = row_percents;
    }
    set rowCount(row_count) {
        if (row_count <= 1) {
            return;
        }
        let precent = 1.0 / row_count;
        this._row_percents = new Array(row_count).fill(precent);
    }
    get rowCount() {
        return this._row_percents.length + 1;
    }
    set columnCount(column_count) {
        if (column_count < 1) {
            return;
        }
        else if (column_count == 1) {
            this._column_percents = new Array(1).fill(1);
            return;
        }
        let precent = 1.0 / column_count;
        this._column_percents = new Array(column_count).fill(precent);
    }
    get columnCount() {
        return this._column_percents.length + 1;
    }
    get rowPercents() {
        return this._row_percents;
    }
    _drawEntity(geo) {
        this._des.drawMap(geo);
    }
    //通过行列号计算矩形框的绝对位置;
    getRect(row, column) {
        if (row > this._row_percents.length || column > this._column_percents.length) {
            return null;
        }
        let x_min = this._box.getMinimum().x;
        let y_min = this._box.getMinimum().y;
        let x_max = 0.0;
        let y_max = 0.0;
        for (let i = 0; i < column + 1; ++i) {
            if (i == column) {
                x_max = x_min + this._box.getWidth() * this._column_percents[i];
                break;
            }
            x_min += this._box.getWidth() * this._column_percents[i];
        }
        for (let i = 0; i < row + 1; ++i) {
            if (i == row) {
                y_max = y_min + this._box.getHeight() * this._row_percents[i];
                break;
            }
            y_min += this._box.getHeight() * this._row_percents[i];
        }
        return new ltpoint_1.RBox(x_min, y_min, x_max, y_max);
    }
    //绘制;
    draw() {
        //绘制表格;
        let rect = this._box;
        let rect_line = ltutils_1.LTUtils.boxToPolyline(rect);
        this._drawEntity(rect_line);
        //绘制列;
        let x_pos = rect.getMinimum().x;
        for (let i = 0; i < this._column_percents.length - 1; ++i) {
            x_pos += rect.getWidth() * this._column_percents[i];
            let line = new ltentity_1.LTLine(new ltpoint_1.LTPoint(x_pos, rect.getMinimum().y), new ltpoint_1.LTPoint(x_pos, rect.getMaximum().y));
            this._drawEntity(line);
        }
        let y_pos = rect.getMinimum().y;
        for (let i = 0; i < this._row_percents.length - 1; ++i) {
            y_pos += rect.getHeight() * this._row_percents[i];
            let line = new ltentity_1.LTLine(new ltpoint_1.LTPoint(rect.getMinimum().x, y_pos), new ltpoint_1.LTPoint(rect.getMaximum().x, y_pos));
            this._drawEntity(line);
        }
    }
}
class DesignGraphcisCommandImpl {
    destroy() {
    }
    constructor(cadmap, is_update = false) {
        this._map = null;
        this._scale_factor = 1.0;
        this._result_features = [];
        this._is_update = false;
        this._map = cadmap;
        this._outter_box = new ltpoint_1.RBox(0, 0, 84100, 59400); //A1的纸张大小;
        this._inner_box = new ltpoint_1.RBox(2174, 1000, 83100, 57400);
        this._x_spliter = 75000;
        this._is_update = is_update;
    }
    //创建一个货架的原理图的喷头块
    createBlock(block_name, geos) {
        if (this._map == null) {
            return;
        }
        if (this._map.dwgservice.hasBlock(block_name)) {
            return;
        }
        let blockRef = new ltentity_1.LTBlockReference();
        for (let sub_entity of geos) {
            blockRef.addEntity(sub_entity);
        }
        this._map.dwgservice.setBlock(block_name, blockRef);
    }
    //根据仓库立管名字获取喷头名字
    findRiserHead(riser_name) {
        if (this._map == null) {
            return [];
        }
        let layer = this._map.getLayerByName(sprinklerutils_1.BRANCHPIPE_LAYER);
        if (layer == null) {
            return [];
        }
        let riser_block_names = [];
        riser_block_names.push("直立型喷头");
        riser_block_names.push("下垂型喷头");
        riser_block_names.push("吊顶型上下喷");
        const findRiserHeadCallBlock = (feat) => {
            let entity_point = feat.getGeometry();
            if (!(entity_point instanceof ltentity_1.LTEntityPoint)) {
                return false;
            }
            let entity_point1 = entity_point;
            let block_name = entity_point1.subData().blockName;
            if (feat._xdata.riser_name == null || feat._xdata.riser_name != riser_name) {
                return false;
            }
            if (!riser_block_names.includes(block_name)) {
                return false;
            }
            return true;
        };
        return layer.getFeatures(null, findRiserHeadCallBlock);
    }
    generateHouse(box, insert_point, floor_num) {
        //查找范围内的立管，并分组;
        this.insertPoint = insert_point;
        const createPenHeadBlock = () => {
            let points = [];
            points.push(new ltpoint_1.LTPoint(-90, 0));
            points.push(new ltpoint_1.LTPoint(90, 0));
            points.push(new ltpoint_1.LTPoint(0, -90));
            points.push(new ltpoint_1.LTPoint(-90, 0));
            let polyline = new ltentity_1.LTPolyline(points);
            let line = new ltentity_1.LTLine(new ltpoint_1.LTPoint(-90, -90), new ltpoint_1.LTPoint(90, -90));
            let geos = [polyline, line];
            this.createBlock("pentou", geos);
        };
        //添加阀门块;
        const createValveBlock = (pos) => {
            let block_name = "原理阀门";
            let points = [];
            points.push(new ltpoint_1.LTPoint(-150, 86));
            points.push(new ltpoint_1.LTPoint(150, -86));
            points.push(new ltpoint_1.LTPoint(150, 86));
            points.push(new ltpoint_1.LTPoint(-150, -86));
            points.push(new ltpoint_1.LTPoint(-150, 86));
            let polyline = new ltentity_1.LTPolyline(points);
            let vor_line = new ltentity_1.LTLine(new ltpoint_1.LTPoint(0, -86), new ltpoint_1.LTPoint(0, 86));
            this.createBlock(block_name, [polyline, vor_line]);
            let entity_point = new ltentity_1.LTEntityPoint(pos);
            entity_point.blockName = block_name;
            entity_point.setLayerName(sprinklerutils_1.SPRINKLER_NOZZLE_LAYER);
            return entity_point;
        };
        createPenHeadBlock();
        let riser_list = this.findRiser(box);
        let riser_grou_rows = new Map();
        //通过行号分组;
        for (let riser of riser_list) {
            let row_index = riser._xdata.row_index;
            if (row_index === undefined) {
                continue;
            }
            let features = riser_grou_rows.get(row_index);
            if (riser_grou_rows.get(row_index) == null) {
                riser_grou_rows.set(row_index, []);
            }
            riser_grou_rows.get(row_index).push(riser);
        }
        if (riser_grou_rows.size == 0) //通过坐标分组
         {
            for (let riser of riser_list) {
                let entity_point = riser.getGeometry();
                if (entity_point === undefined) {
                    continue;
                }
                let y = entity_point.subData().position.y;
                let found = false;
                for (let [key, value] of riser_grou_rows) {
                    if (Math.abs(key - y) <= 1e-2) { // 判断y值是否在容差范围内
                        found = true;
                        break;
                    }
                }
                if (found) {
                    riser_grou_rows.get(y).push(riser);
                }
                else {
                    riser_grou_rows.set(y, [riser]);
                }
            }
        }
        if (riser_grou_rows.size == 0) {
            return;
        }
        //再次分组;
        let riser_groups = new Map();
        for (let group_item of riser_grou_rows) {
            let group_risers = group_item[1][0];
            const group_index = group_risers._xdata.groupIndex;
            if (group_index === undefined) {
                continue;
            }
            let features = riser_groups.get(group_index);
            if (riser_groups.get(group_index) == null) {
                riser_groups.set(group_index, []);
            }
            riser_groups.get(group_index).push(group_item);
        }
        if (riser_groups.size == 0) {
            riser_groups.set(0, riser_grou_rows);
        }
        //可以按比例缩小大小;
        let cell_vertical_height = 1000.0;
        let cell_horizontal_width = 4000.0;
        //初始的位置;
        let frame_x = insert_point.x + 2000;
        let frame_y = insert_point.y + 2000;
        let riser_group_index = riser_groups.size;
        let sortedMap = new Map([...riser_groups.entries()].sort((a, b) => b[1].length - a[1].length));
        for (let riser_group_item of sortedMap) { //分组绘制
            --riser_group_index;
            let center_x = frame_x;
            let center_y = frame_y;
            let riser_rows = riser_group_item[1];
            let offset_x = 0;
            let offset_y = 0;
            //绘制每一组斜线的点组;
            let bias_points = [];
            for (let row_index = 0; row_index < riser_rows.length; row_index++) //每一组中
             {
                let row_item = riser_rows[row_index];
                let row_risers = row_item[1];
                if (row_risers == null) {
                    continue;
                }
                if (row_index == 0) {
                    //从左到右；
                    center_x = frame_x + cell_horizontal_width * 0.5 + row_risers.length * cell_horizontal_width * riser_group_index;
                    //从下到上；
                    center_y = frame_y + (floor_num * cell_vertical_height + 1.5 * cell_vertical_height) * (riser_groups.size - riser_group_index) * 1.5;
                }
                //45度角绘制;
                if (row_index > 0) {
                    offset_x += 0.5 * cell_horizontal_width * row_risers.length;
                    offset_y += floor_num * cell_vertical_height + 1.5 * cell_vertical_height;
                }
                //绘制每一排的主管道;
                let main_pos = new ltpoint_1.LTPoint(center_x + offset_x, center_y + offset_y);
                let main_y = main_pos.x + cell_horizontal_width * (row_risers.length + 1);
                let main_pipe = new ltentity_1.LTLine(new ltpoint_1.LTPoint(main_pos.x, main_pos.y), new ltpoint_1.LTPoint(main_y, main_pos.y));
                bias_points.push(new ltpoint_1.LTPoint(main_y, main_pos.y));
                main_pipe.setLayerName(sprinklerutils_1.MAINPIPE_LAYER);
                this.drawMap(main_pipe);
                let pipe_pos = main_pos.clone();
                pipe_pos.y -= cell_vertical_height * 0.25;
                //绘制一排的喷淋头;
                this.drawHouseRiser(row_risers, pipe_pos, floor_num, cell_horizontal_width, cell_vertical_height);
                //绘制每一排的水流指示器;
                let y_water_pos = pipe_pos.y - floor_num * cell_vertical_height + cell_vertical_height * 0.25;
                let water_pipe = new ltentity_1.LTLine(new ltpoint_1.LTPoint(main_pos.x, y_water_pos), new ltpoint_1.LTPoint(main_pos.x + cell_horizontal_width * row_risers.length, y_water_pos));
                water_pipe.setLayerName(sprinklerutils_1.MAINPIPE_LAYER);
                this.drawMap(water_pipe);
                //添加一个截止阀;
                let block_pos = water_pipe.getEnd().plus(new ltpoint_1.LTPoint(150, 0));
                let value_block = createValveBlock(block_pos);
                this.drawMap(value_block);
            }
            if (bias_points.length > 1) {
                let bias_line = new ltentity_1.LTPolyline(bias_points);
                bias_line.setLayerName(sprinklerutils_1.MAINPIPE_LAYER);
                this.drawMap(bias_line);
                //取中间的点;
                let half_pos_index = Math.floor(bias_points.length / 2);
                let p1 = bias_points[half_pos_index];
                let p2 = new ltpoint_1.LTPoint(p1.x + cell_horizontal_width * 0.5, p1.y);
                //计算斜线的角度;
                let start_p1 = bias_points[0];
                let end_p1 = bias_points[bias_points.length - 1];
                let angle = end_p1.getAngleTo(start_p1);
                let distance = Math.sqrt(cell_horizontal_width * 0.5 * cell_horizontal_width * 0.5 * 2);
                let p3 = p2.calculatePoint(angle, -distance);
                //let p3=new LTPoint(p2.x+cell_horizontal_width*0.5,p2.y+cell_horizontal_width*0.5);
                let pline = new ltentity_1.LTPolyline([p1, p2, p3]);
                pline.setLayerName(sprinklerutils_1.MAINPIPE_LAYER);
                this.drawMap(pline);
            }
        }
    }
    //绘制一横排立管,每个立管又有层数;
    drawHouseRiser(row_risers, pos, floor_num, cell_horizontal_width = 6000, cell_vertical_height = 3000) {
        var _a, _b;
        //查找喷头
        if (!row_risers || row_risers.length === 0) {
            return;
        }
        let layer = (_a = this._map) === null || _a === void 0 ? void 0 : _a.getLayerByName(sprinklerutils_1.BRANCHPIPE_LAYER);
        if (layer == null) {
            return;
        }
        function drawBlock(block_pos, block_name, layer) {
            if (!block_pos || !block_name) {
                return null;
            }
            let entity_point = new ltentity_1.LTEntityPoint(block_pos);
            entity_point.blockName = block_name;
            entity_point.setLayerName(sprinklerutils_1.SPRINKLER_NOZZLE_LAYER);
            return entity_point;
        }
        //绘制一排喷头
        const drawLayerPentou = (center_pos, temp_layer) => {
            if (!center_pos || !temp_layer) {
                return;
            }
            let head_list_count = 8;
            //绘制一个横向的直线;
            let line_start_point = new ltpoint_1.LTPoint(center_pos.x - 0.5 * cell_horizontal_width, center_pos.y);
            let line_end_point = new ltpoint_1.LTPoint(center_pos.x + 0.5 * cell_horizontal_width, center_pos.y);
            let new_line = new ltentity_1.LTLine(line_start_point, line_end_point);
            new_line.setLayerName(sprinklerutils_1.BRANCHPIPE_LAYER);
            this.drawMap(new_line);
            let x_offset = cell_horizontal_width / (head_list_count - 1);
            //绘制喷头;
            for (let k = 0; k < head_list_count; k++) {
                let x_pos = line_start_point.x + k * x_offset;
                let y_pos = line_start_point.y;
                let sub_line = new ltentity_1.LTLine(new ltpoint_1.LTPoint(x_pos, y_pos), new ltpoint_1.LTPoint(x_pos, y_pos - 200));
                sub_line.setLayerName(sprinklerutils_1.BRANCHPIPE_LAYER);
                this.drawMap(sub_line);
                let block_point = drawBlock(sub_line.subData().getEndPoint(), "pentou", temp_layer);
                if (block_point) {
                    this.drawMap(block_point);
                }
            }
        };
        //从中间绘制一个立管线;
        for (let i = 0; i < row_risers.length; i++) {
            let row_pos = new ltpoint_1.LTPoint(pos.x + i * (cell_horizontal_width + cell_horizontal_width * 0.2), pos.y);
            for (let j = 0; j < floor_num; j++) { //绘制五层
                let center_pos = new ltpoint_1.LTPoint(row_pos.x, row_pos.y - j * cell_vertical_height);
                drawLayerPentou(center_pos, layer);
            }
            //绘制垂直的立管线;
            let riser_height = floor_num * cell_vertical_height - cell_vertical_height * 0.25;
            //需要出头;
            let riser_line = new ltentity_1.LTLine(new ltpoint_1.LTPoint(row_pos.x, row_pos.y + cell_vertical_height * 0.25), new ltpoint_1.LTPoint(row_pos.x, row_pos.y - riser_height));
            riser_line.setLayerName(sprinklerutils_1.BRANCHPIPE_LAYER);
            this.drawMap(riser_line);
            //绘制立管标注;
            let riser_name = (_b = row_risers[i]._xdata) === null || _b === void 0 ? void 0 : _b.riser_name;
            if (riser_name == undefined || riser_name == null) {
                continue;
            }
            //绘制立管标注线;
            let row_pos_y = row_pos.y + cell_vertical_height * 0.5;
            let riser_text_line = new ltentity_1.LTLine(new ltpoint_1.LTPoint(row_pos.x, row_pos.y + cell_vertical_height * 0.25), new ltpoint_1.LTPoint(row_pos.x, row_pos_y));
            riser_text_line.setLayerName(sprinklerutils_1.AUTOPIPE_TEXT_LAYER);
            this.drawMap(riser_text_line);
            //绘制立管横向标注线;
            let riser_text_line_2 = new ltentity_1.LTLine(new ltpoint_1.LTPoint(row_pos.x, row_pos_y), new ltpoint_1.LTPoint(row_pos.x + cell_horizontal_width * 0.125, row_pos_y));
            riser_text_line_2.setLayerName(sprinklerutils_1.AUTOPIPE_TEXT_LAYER);
            this.drawMap(riser_text_line_2);
            //绘制立管标注文字;
            let riser_text = new ltentity_2.LTText(new ltpoint_1.LTPoint(row_pos.x, row_pos_y));
            riser_text.setHeight(200);
            riser_text.text = riser_name;
            riser_text.setLayerName(sprinklerutils_1.AUTOPIPE_TEXT_LAYER);
            this.drawMap(riser_text);
        }
    }
    set insertPoint(point) {
        this._outter_box = ltpoint_1.RBox.fromWidthHeight(point.x, point.y, 84100, 59400);
        let offset_point = new ltpoint_1.LTPoint(2174, 1000);
        offset_point = point.plus(offset_point);
        this._inner_box = ltpoint_1.RBox.fromWidthHeight(offset_point.x, offset_point.y, 83100 - 2174, 57400 - 1000);
        this._x_spliter = point.x + 75000;
    }
    get insertPoint() {
        return this._outter_box.getMinimum();
    }
    //查找立管列表;
    findRiser(riser_box) {
        //查找喷淋立管;
        if (this._map == null) {
            return [];
        }
        let layer = this._map.getLayerByName(sprinklerutils_1.STANDPIPE_LAYER);
        if (layer == null) {
            return [];
        }
        function findRiserBlock(feat) {
            let sub_geometry = feat.getGeometry();
            if (!(sub_geometry instanceof ltentity_1.LTEntityPoint)) {
                return false;
            }
            let block_name = sub_geometry.subData().blockName;
            if (block_name == null) {
                return false;
            }
            if (block_name != sprinklerutils_1.STANDPIPE_NAME) {
                return false;
            }
            //包含选择;
            if (!riser_box.contain(feat.getGeometry().getBoundingBox())) {
                return false;
            }
            if (feat._xdata.riser_name == null) {
                return false;
            }
            return true;
        }
        return layer.getFeatures(riser_box, findRiserBlock);
    }
    //通过立管名找到立管;
    findRiserByName(riser_name) {
        if (this._map === null) {
            return null;
        }
        let layer = this._map.getLayerByName(sprinklerutils_1.STANDPIPE_LAYER);
        if (layer == null) {
            return null;
        }
        function FeatureCallback(fea) {
            let geo = fea.getGeometry();
            if (!(geo instanceof ltentity_1.LTEntityPoint)) {
                return false;
            }
            let block_name = geo.subData().blockName;
            if (block_name == null) {
                return false;
            }
            if (block_name != sprinklerutils_1.STANDPIPE_NAME) {
                return false;
            }
            if (!(geo instanceof ltentity_1.LTEntityPoint)) {
                return false;
            }
            if (fea._xdata.riser_name == riser_name) {
                return true;
            }
            return false;
        }
        let result = layer.getFeatures(null, FeatureCallback);
        if (result.length == 0) {
            return null;
        }
        return result[0];
    }
    //查找末端试水装置;
    findTailByName(riser_name) {
        if (this._map === null) {
            return null;
        }
        let layer = this._map.getLayerByName(sprinklerutils_1.BRANCHPIPE_LAYER);
        if (layer == null) {
            return null;
        }
        function FeatureCallback(fea) {
            let geo = fea.getGeometry();
            if (!(geo instanceof ltentity_1.LTEntityPoint)) {
                return false;
            }
            let block_name = geo.subData().blockName;
            if (block_name != sprinklerutils_1.BLOCK_DEADLINE) {
                return false;
            }
            if (!(geo instanceof ltentity_1.LTEntityPoint)) {
                return false;
            }
            if (fea._xdata.riser_name == riser_name) {
                return true;
            }
            return false;
        }
        let result = layer.getFeatures(null, FeatureCallback);
        if (result.length == 0) {
            return null;
        }
        return result[0];
    }
    //通过相交线获取图形;
    findIntersectByEntity(intersect_shape, layer, callback) {
        function query_callback(sub_feature) {
            if (!callback(sub_feature)) {
                return false;
            }
            let sub_geometry = sub_feature.getGeometry();
            if (intersect_shape.intersectsWith(sub_geometry)) {
                return true;
            }
            return false;
        }
        let box = intersect_shape.getBoundingBox();
        let results = layer.getFeatures(box, query_callback);
        if (results.length == 0) {
            return null;
        }
        return results[0];
    }
    //直接通过立管名字查找水流指示器;
    findFlowIndicatorWithAttribute(box, riser_name) {
        //查找水流指示器;
        if (this._map == null) {
            return [];
        }
        function isFindBlock(block_feature) {
            let block_geo = block_feature.getGeometry();
            if (!(block_geo instanceof ltentity_1.LTEntityPoint)) {
                return false;
            }
            let block_name = block_geo.subData().blockName;
            if (block_name === null) {
                return false;
            }
            //查找水流指示器块名--流量计
            if (block_name !== sprinklerutils_1.WATERFLOW_NAME) {
                return false;
            }
            if (block_feature._xdata.riser_name !== riser_name) {
                return false;
            }
            return true;
        }
        ;
        return this._map.selectFeatures(box, isFindBlock);
    }
    converToEntity(geometry) {
        if (geometry instanceof ltentity_1.LTLine || geometry instanceof ltentity_1.LTPolyline) {
            if (geometry instanceof ltentity_1.LTLine) {
                let ret = [];
                ret.push(geometry.getStart());
                ret.push(geometry.getEnd());
                geometry = new ltentity_1.LTPolyline(ret);
            }
            //这条线应该是一个直线;
            let box = geometry.getBoundingBox();
            if (box.getWidth() < 1e-5 || box.getHeight() < 1e-5) {
                return geometry;
            }
        }
        return null;
    }
    //获取立管标注,通过块的扩展属性;
    findRiserName(block) {
        //通过块的扩展属性;
        if (this._map == null) {
            return "";
        }
        if (block._xdata.riser_name != null) {
            //通过关系查找;
            return block._xdata.riser_name;
        }
        let layer = this._map.getLayerByName(sprinklerutils_1.PIPDIAMETER_ANNO_LAYER);
        if (layer == null) {
            return "";
        }
        function line_callback(line) {
            let sub_geo = line.getGeometry();
            if (sub_geo instanceof ltentity_1.LTLine || sub_geo instanceof ltentity_1.LTPolyline) {
                return true;
            }
            return false;
        }
        let line = this.findIntersectByEntity(block.getGeometry(), layer, line_callback);
        if (line == null) {
            return "";
        }
        let sub_line = line.getGeometry();
        if (sub_line == null) {
            return "";
        }
        let block_box = block.getGeometry().data.getBoundingBox();
        let result_point = sub_line.subData().getStartPoint();
        if (block_box.contain(sub_line.subData().getStartPoint())) {
            result_point = sub_line.subData().getEndPoint();
        }
        //查询周边的文字;
        let query_box = ltpoint_1.RBox.fromCenter(result_point, sub_line.subData().getLength() * 0.5);
        function text_callback(line) {
            let sub_geo = line.getGeometry();
            if (!(sub_geo instanceof ltentity_2.LTText)) {
                return false;
            }
            let text = sub_geo.subData().text;
            if (text.startsWith("ZL")) {
                return true;
            }
            return false;
        }
        let notation = layer.getFeatures(query_box, text_callback);
        if (notation == null) {
            return "";
        }
        var geo_text = notation[0].getGeometry();
        return geo_text.subData().text;
    }
    generate(insert_point, floor_frames) {
        if (this._map == null) {
            return;
        }
        this.insertPoint = insert_point;
        let layer = this._map.getLayerByName(sprinklerutils_1.AUTOPIPE_LAYER);
        if (layer === null) {
            this._map.printMessage(`没有找到${sprinklerutils_1.AUTOPIPE_LAYER}`);
            return;
        }
        let riser_map = new Map();
        let floor_index = 0;
        for (let sub_floor of floor_frames) {
            //获取这个层所有的立管;
            let frame_box = sub_floor.box;
            let risers = this.findRiser(frame_box);
            if (risers.length == 0) {
                continue;
            }
            let main_foor = {
                floor_no: floor_index++,
                floor_height: sub_floor.height * 1.0,
                floor_indicator: [],
            };
            //获取这个立管的信息;
            for (let riser of risers) {
                let riser_name = this.findRiserName(riser);
                let sub_clone_floor = Object.assign({}, main_foor);
                //查找水流指示器;
                let indicator = this.findFlowIndicatorWithAttribute(frame_box, riser_name);
                if (indicator == null || indicator.length == 0) {
                    this._map.printMessage(`没有找到立管名为:${riser_name}的水流指示器`);
                    continue;
                }
                sub_clone_floor.floor_indicator = indicator;
                let floor_array = riser_map.get(riser_name);
                if (floor_array != null) {
                    floor_array.push(sub_clone_floor);
                }
                else {
                    let floor_array = [];
                    floor_array.push(sub_clone_floor);
                    riser_map.set(riser_name, floor_array);
                }
            }
        }
        if (riser_map.size === 0) {
            this._map.printMessage(`没有找到立管`);
        }
        //根据楼层排序;
        for (let [key, value] of riser_map) {
            value.sort((a, b) => {
                return a.floor_no - b.floor_no;
            });
        }
        let data_frame = this.getDrawFrame(riser_map);
        if (data_frame == null) {
            return;
        }
        this.drawRisers(data_frame);
        if (this._is_update == false) {
            this.drawFloor(data_frame);
            //绘制自动喷水原理图文字;
            this.drawPrincipleText();
        }
    }
    drawPrincipleText() {
        let principle_text = new ltentity_2.LTText(new ltpoint_1.LTPoint(0, 0));
        principle_text.setHeight(1000);
        principle_text.fontName = "汉仪中等线";
        principle_text.text = "自动喷水灭火系统原理图";
        principle_text.subData().initWH();
        let text_width = principle_text.subData().width;
        let start_text_pos = this.insertPoint.x + (this._x_spliter - this._inner_box.getMinimum().x - text_width) / 2;
        let text_base_height = 2700;
        var text_hor_line1 = new ltentity_1.LTLine(new ltpoint_1.LTPoint(start_text_pos, this._inner_box.getMinimum().y + text_base_height), new ltpoint_1.LTPoint(start_text_pos + text_width, this._inner_box.getMinimum().y + text_base_height));
        text_base_height += 50;
        var text_hor_line2 = new ltentity_1.LTLine(new ltpoint_1.LTPoint(start_text_pos, this._inner_box.getMinimum().y + text_base_height), new ltpoint_1.LTPoint(start_text_pos + text_width, this._inner_box.getMinimum().y + text_base_height));
        text_base_height += 50;
        principle_text.subData().setPosition(new ltpoint_1.LTPoint(start_text_pos, this._inner_box.getMinimum().y + text_base_height));
        this.drawMap(text_hor_line1);
        this.drawMap(text_hor_line2);
        this.drawMap(principle_text);
    }
    drawFloor(riser_frames) {
        //绘制楼层相关标记;
        let floor_set = [];
        for (let riser_frame of riser_frames) {
            function hasFloor(floor_no) {
                for (let floor of floor_set) {
                    if (floor.floor_no == floor_no) {
                        return true;
                    }
                }
                return false;
            }
            for (let floors of riser_frame.floor_groups) {
                for (let sub_floor of floors.floors) {
                    if (hasFloor(sub_floor.floor_no)) {
                        continue;
                    }
                    floor_set.push(sub_floor);
                }
            }
            floor_set.sort((a, b) => { return a.floor_no - b.floor_no; });
        }
        let x_offset = this._inner_box.getMinimum().x + 1000;
        const drawFloorLine = (floor_y_pos, floor_no, floor_height, is_top) => {
            let floor_spliter = new ltentity_1.LTLine(new ltpoint_1.LTPoint(this._inner_box.getMinimum().x, floor_y_pos), new ltpoint_1.LTPoint(this._x_spliter, floor_y_pos));
            this.drawMap(floor_spliter);
            //绘制楼层块;
            let floor_block = new ltentity_1.LTEntityPoint(new ltpoint_1.LTPoint(x_offset, floor_y_pos));
            floor_block.setColor("#00FF00");
            floor_block.blockName = "BG";
            //添加楼层文字;
            let floor_text = new ltentity_2.LTText(new ltpoint_1.LTPoint(x_offset, floor_y_pos + floor_block.getBoundingBox().getHeight() + 100));
            floor_text.setHeight(200);
            floor_text.setColor("#00FF00");
            floor_text.text = floor_height.toFixed(3);
            //添加楼层文字;
            let f_text = new ltentity_2.LTText(new ltpoint_1.LTPoint(x_offset + 200, floor_y_pos + floor_block.getBoundingBox().getHeight() + 500));
            f_text.setHeight(200);
            f_text.setColor("#00FF00");
            if (is_top) {
                f_text.text = `屋顶`;
            }
            else {
                f_text.text = `${floor_no}F`;
            }
            this.drawMap(floor_block);
            this.drawMap(floor_text);
            this.drawMap(f_text);
        };
        for (let riser_frame of riser_frames) {
            let index = 0;
            drawFloorLine(riser_frame.start_pos.y - 3000, 1, 0.00, false);
            for (let value of floor_set) {
                //添加楼层分割线的长度;
                let is_top = false;
                if (index == floor_set.length - 1) {
                    is_top = true;
                }
                drawFloorLine(riser_frame.start_pos.y + value.floor_no * 3000, (value.floor_no + 2), value.floor_height, is_top);
                index += 1;
            }
        }
    }
    getmaxFloorNo(floors) {
        let max_floor_pos = 0;
        for (let floor of floors) {
            if (floor.floor_no > max_floor_pos) {
                max_floor_pos = floor.floor_no;
            }
        }
        return max_floor_pos;
    }
    ;
    //获取立管的排布长度;
    getRiserLength(floors) {
        let max_count = 0;
        for (let floor of floors) {
            if (floor.floor_indicator.length > max_count) {
                max_count = floor.floor_indicator.length;
            }
        }
        return max_count * 5600 + 2000;
    }
    getDrawFrame(riser_map) {
        if (this._map == null) {
            return null;
        }
        let riser_list = [];
        for (let riser of riser_map) {
            let sub_floor = { riser_name: riser[0], floors: riser[1] };
            riser_list.push(sub_floor);
        }
        //排序;
        function compare(a, b) {
            if (a.floors.length == b.floors.length) {
                return a.riser_name.localeCompare(b.riser_name);
            }
            return b.floors.length - a.floors.length;
        }
        riser_list.sort(compare);
        let data_frames = [];
        for (let sub_riser of riser_list) {
            //计算所需要的x偏移量;
            let data_frame = null;
            let x_offset = this.getRiserLength(sub_riser.floors);
            for (let i = 0; i < data_frames.length; i++) {
                let last_frame = data_frames[i];
                if (this._x_spliter > (last_frame.offset + x_offset + last_frame.start_pos.x)) {
                    data_frame = last_frame;
                    data_frame.offset += x_offset;
                    break;
                }
            }
            if (data_frame == null) {
                data_frame = {
                    floor_groups: [],
                    start_pos: this.insertPoint.plus(new ltpoint_1.LTPoint(6000, 0)),
                    floor_max_no: 0,
                    offset: 0,
                };
                data_frame.offset = x_offset;
                data_frames.push(data_frame);
            }
            //复合条件的data_frame;
            data_frame.floor_groups.push(sub_riser);
        }
        //合并获取到所有的层高;
        let data_index = 0;
        let y_max_pos = this.insertPoint.y;
        for (let data_frame of data_frames) {
            let max_floor_no = 0;
            for (let sub_riser of data_frame.floor_groups) {
                let val = this.getmaxFloorNo(sub_riser.floors);
                if (max_floor_no < val) {
                    max_floor_no = val;
                }
            }
            if (data_index == 0) {
                y_max_pos += 10000;
            }
            else {
                y_max_pos += 10000 + max_floor_no * 3000;
            }
            data_index++;
            data_frame.floor_max_no = max_floor_no;
            data_frame.start_pos = new ltpoint_1.LTPoint(this.insertPoint.x + 6000, y_max_pos);
        }
        return data_frames;
    }
    drawRisers(data_frames) {
        if (this._map == null) {
            return;
        }
        //绘制riser;
        for (let data_frame of data_frames) {
            let x_pos = data_frame.start_pos.x;
            let x_offset = 0;
            for (let sub_riser of data_frame.floor_groups) {
                this.drawRiser(sub_riser.riser_name, sub_riser.floors, new ltpoint_1.LTPoint(x_pos + x_offset, data_frame.start_pos.y - 4000));
                x_offset += this.getRiserLength(sub_riser.floors);
            }
        }
    }
    //添加标注
    drawCenterAnnotation(text, start_pos, end_pos, layer_name, text_height = 200) {
        //根据线的方向绘制文字
        let angle = start_pos.getAngleTo(end_pos);
        let geo_text = new ltentity_2.LTText(new ltpoint_1.LTPoint(0, 0));
        geo_text.text = text;
        geo_text.setHeight(text_height);
        geo_text.subData().initWH();
        let text_width = geo_text.subData().width;
        let start_length = (start_pos.getDistanceTo(end_pos) - text_width) / 2;
        //在中心线上绘制;
        let pos = start_pos.calculatePoint(angle, start_length);
        let offset_pos = new ltpoint_1.LTPoint(10, 0);
        offset_pos = offset_pos.calculatePoint(angle, 1);
        geo_text.setRotate(angle);
        geo_text.setLayerName(layer_name);
        geo_text.subData().setPosition(pos.plus(offset_pos));
        this.drawMap(geo_text);
    }
    //计算文字宽度;
    calculateTextWidth(text, text_height = 200) {
        let geo_text = new ltentity_2.LTText(new ltpoint_1.LTPoint(0, 0));
        geo_text.text = text;
        geo_text.setHeight(text_height);
        geo_text.setColor("#FFFF00");
        geo_text.subData().initWH();
        return geo_text.subData().width;
    }
    drawAnnotationbyPos(text, pos, angle, layer_name, text_height = 200) {
        //根据线的方向绘制文字
        let geo_text = new ltentity_2.LTText(pos);
        geo_text.setLayerName(layer_name);
        geo_text.setHeight(text_height);
        geo_text.setColor("#FFFF00");
        geo_text.text = text;
        geo_text.subData().setRotate(angle);
        this.drawMap(geo_text);
    }
    //给最后一个生成的要素添加标记
    setLastFetureTag(tag) {
        if (this._map == null) {
            return;
        }
        if (this._result_features.length == 0) {
            return;
        }
        let last_feature = this._result_features[this._result_features.length - 1];
        if (last_feature != null) {
            last_feature._xdata.source = tag;
        }
    }
    getPipeDiamter(riser, flag) {
        if (this._map == null) {
            return 'DN150';
        }
        //通过自喷管找到对应的自喷管;
        function callback(feature) {
            let geo = feature.getGeometry();
            if (!(geo instanceof ltentity_1.LTPolyline || geo instanceof ltentity_1.LTLine)) {
                return false;
            }
            //与立管有相交或者水流指示器有相交;
            if (flag == FlagType.FlagRiser) {
                if (!riser.getGeometry().intersectsWith(geo)) {
                    return false;
                }
                return true;
            }
            if (feature._xdata === null || feature._xdata.pipeSize === undefined) {
                return false;
            }
            return true;
        }
        let result_featues = [];
        if (flag == FlagType.FlagRiser) {
            result_featues = this._map.selectFeatures(riser.getGeometry().getBoundingBox(), callback);
        }
        else if (flag == FlagType.FlagWater) {
            //喷淋主管图层;
            let main_layer = this._map.getLayerByName(sprinklerutils_1.MAINPIPE_LAYER);
            if (main_layer === null) {
                return "DN150";
            }
            result_featues = main_layer.getFeatures(riser.getGeometry().getBoundingBox(), callback);
        }
        else if (flag == FlagType.FlagTail) {
            let branchpipe_layer = this._map.getLayerByName(sprinklerutils_1.BRANCHPIPE_LAYER);
            if (branchpipe_layer === null) {
                return "DN150";
            }
            result_featues = branchpipe_layer.getFeatures(riser.getGeometry().getBoundingBox(), callback);
        }
        if (result_featues.length == 0) {
            return 'DN150';
        }
        if (flag == FlagType.FlagRiser) {
            //暂时无法计算;
            return 'DN150';
        }
        //取feture的pipeSize的最大的值;
        let max_pipe_size = -1;
        for (let feature of result_featues) {
            if (feature._xdata.pipeSize !== undefined) {
                //将pipeSize移除前面的DN再转换为数字;
                let pipe_size = feature._xdata.pipeSize;
                pipe_size = pipe_size.replace('DN', '');
                let pipe_size_num = parseInt(pipe_size);
                if (pipe_size_num > max_pipe_size) {
                    max_pipe_size = pipe_size_num;
                }
            }
        }
        if (max_pipe_size == -1) {
            return 'DN150';
        }
        return 'DN' + max_pipe_size;
    }
    //绘制立管;
    drawRiser(riser_name, floors, riser_pos) {
        if (this._map == null) {
            return 0;
        }
        //立管标记;
        const riser_tag = "autoDesignRiser";
        let result_x_pos = 0;
        //绘制截断器;
        let result_geometris = [];
        let x_pos = riser_pos.x;
        let y_pos = riser_pos.y;
        let first_floor_y_pos = y_pos + 1000;
        let cutter = new ltentity_1.LTLine(new ltpoint_1.LTPoint(x_pos - 30, y_pos - 30), new ltpoint_1.LTPoint(x_pos + 30, y_pos + 30));
        cutter.setLayerName(sprinklerutils_1.MAINPIPE_LAYER);
        result_geometris.push(cutter);
        let riser_height = first_floor_y_pos + (this.getmaxFloorNo(floors)) * 3000 + 2200;
        let main_riser = new ltentity_1.LTLine(new ltpoint_1.LTPoint(x_pos, y_pos), new ltpoint_1.LTPoint(x_pos, riser_height));
        main_riser.setLayerName(sprinklerutils_1.MAINPIPE_LAYER);
        result_geometris.push(main_riser);
        //在末端添加自动排气阀;
        let pos = new ltpoint_1.LTPoint(x_pos, riser_height);
        let entityPoint = new ltentity_1.LTEntityPoint(pos);
        entityPoint.setIsSub(true);
        entityPoint.setLayerName(sprinklerutils_1.BRANCHPIPE_LAYER);
        entityPoint.blockName = "自动排气阀";
        result_geometris.push(entityPoint);
        var test_distance = 20;
        //增加标注的立管文字;
        {
            let biaozhu_line_height = riser_height + entityPoint.getBoundingBox().getHeight();
            let vor_zl_nation = new ltentity_1.LTLine(new ltpoint_1.LTPoint(x_pos, biaozhu_line_height + 100), new ltpoint_1.LTPoint(x_pos, biaozhu_line_height + 660));
            vor_zl_nation.setLayerName(sprinklerutils_1.AUTOPIPE_TEXT_LAYER);
            vor_zl_nation.setLineType(ltentity_1.linetype_continuous);
            result_geometris.push(vor_zl_nation);
            let riser_text = new ltentity_2.LTText(new ltpoint_1.LTPoint(x_pos, biaozhu_line_height + 660 + test_distance));
            riser_text.setHeight(200);
            riser_text.text = riser_name;
            riser_text.setLayerName(sprinklerutils_1.AUTOPIPE_TEXT_LAYER);
            riser_text.subData().initWH();
            let riser_nation_text_width = riser_text.subData().width;
            result_geometris.push(riser_text);
            let hor_zl_nation = new ltentity_1.LTLine(new ltpoint_1.LTPoint(x_pos, biaozhu_line_height + 660), new ltpoint_1.LTPoint(x_pos + riser_nation_text_width, biaozhu_line_height + 660));
            hor_zl_nation.setLayerName(sprinklerutils_1.AUTOPIPE_TEXT_LAYER);
            //使用默认线型;
            hor_zl_nation.setLineType(ltentity_1.linetype_continuous);
            result_geometris.push(hor_zl_nation);
        }
        //绘制立管标注;
        let max_no = floors[floors.length - 1].floor_no;
        let main_riser_angle = main_riser.getStart().getAngleTo(main_riser.getEnd());
        for (let i = 0; i <= max_no; ++i) {
            let floor_riser_noation = first_floor_y_pos + i * 3000 + 1000;
            let riser_feature = this.findRiserByName(riser_name);
            let diamter = 'DN150';
            if (riser_feature !== null) {
                diamter = this.getPipeDiamter(riser_feature, FlagType.FlagRiser);
            }
            this.drawAnnotationbyPos(diamter, new ltpoint_1.LTPoint(x_pos - test_distance * this._scale_factor, floor_riser_noation), main_riser_angle, sprinklerutils_1.AUTOPIPE_TEXT_LAYER, 200);
            this.setLastFetureTag(riser_tag);
        }
        for (let i = 0; i < floors.length; ++i) {
            //添加每一层的试水装置;
            let sub_floor = floors[i];
            let start_layer_height = first_floor_y_pos + sub_floor.floor_no * 3000 + 2000;
            let x_offset = 0;
            let hor_line = null;
            for (let k = 0; k < sub_floor.floor_indicator.length; ++k) {
                let line = null;
                let floor_diamter = this.getPipeDiamter(sub_floor.floor_indicator[k], FlagType.FlagWater);
                if (k == 0) {
                    x_offset = 1600;
                    if (k == sub_floor.floor_indicator.length - 1) {
                        x_offset = 800;
                    }
                    line = new ltentity_1.LTLine(new ltpoint_1.LTPoint(x_pos, start_layer_height), new ltpoint_1.LTPoint(x_pos + x_offset, start_layer_height));
                    line.setLayerName(sprinklerutils_1.MAINPIPE_LAYER);
                    if (k != sub_floor.floor_indicator.length - 1) {
                        this.drawCenterAnnotation(floor_diamter, new ltpoint_1.LTPoint(x_pos, start_layer_height + 50), new ltpoint_1.LTPoint(x_pos + x_offset - 400, start_layer_height + 50), sprinklerutils_1.PIPDIAMETER_ANNO_LAYER, 200);
                        this.setLastFetureTag(riser_tag);
                    }
                    this.drawCenterAnnotation(floor_diamter, new ltpoint_1.LTPoint(x_pos + x_offset, start_layer_height - 400), new ltpoint_1.LTPoint(x_pos + x_offset + 800, start_layer_height - 400), sprinklerutils_1.PIPDIAMETER_ANNO_LAYER, 200);
                    this.setLastFetureTag(riser_tag);
                }
                else if (k == 1) {
                    x_offset = 1300;
                    //绘制竖线;
                    let ver_line = new ltentity_1.LTLine(new ltpoint_1.LTPoint(x_pos + x_offset, start_layer_height), new ltpoint_1.LTPoint(x_pos + x_offset, start_layer_height + 400));
                    ver_line.setLayerName(sprinklerutils_1.BRANCHPIPE_LAYER);
                    //绘制横线;
                    hor_line = new ltentity_1.LTLine(new ltpoint_1.LTPoint(x_pos + x_offset, start_layer_height + 400), new ltpoint_1.LTPoint(x_pos + x_offset + 5600 * (sub_floor.floor_indicator.length - 1), start_layer_height + 400));
                    hor_line.setLayerName(sprinklerutils_1.BRANCHPIPE_LAYER);
                    for (let j = 0; j < sub_floor.floor_indicator.length - 1; ++j) {
                        this.drawCenterAnnotation(floor_diamter, new ltpoint_1.LTPoint(x_pos + x_offset + 5600 * j, start_layer_height + 450), new ltpoint_1.LTPoint(x_pos + x_offset + 5600 * (j + 1), start_layer_height + 450), sprinklerutils_1.PIPDIAMETER_ANNO_LAYER, 200);
                        this.setLastFetureTag(riser_tag);
                    }
                    result_geometris.push(ver_line);
                    result_geometris.push(hor_line);
                }
                if (k > 0) {
                    if (k == sub_floor.floor_indicator.length - 1) {
                        if (hor_line != null) {
                            line = hor_line;
                        }
                    }
                    else {
                        line = new ltentity_1.LTLine(new ltpoint_1.LTPoint(x_pos + x_offset + 5600 * k, start_layer_height + 400), new ltpoint_1.LTPoint(x_pos + x_offset + 5600 * k, start_layer_height));
                        line.setLayerName(sprinklerutils_1.BRANCHPIPE_LAYER);
                    }
                }
                if (line == null) {
                    continue;
                }
                let sxf_pos = line.getEnd();
                //计算偏移量;
                let sub_result_x_pos = sxf_pos.x - x_pos + 6000;
                if (result_x_pos < sub_result_x_pos) {
                    result_x_pos = sub_result_x_pos;
                }
                if (k > 0) {
                    this.drawCenterAnnotation("DN150", new ltpoint_1.LTPoint(sxf_pos.x, sxf_pos.y - 450), new ltpoint_1.LTPoint(sxf_pos.x + 800, sxf_pos.y - 450), sprinklerutils_1.PIPDIAMETER_ANNO_LAYER, 200);
                    this.setLastFetureTag(riser_tag);
                }
                let entity_point = new ltentity_1.LTEntityPoint(sxf_pos);
                entity_point.setLayerName(sprinklerutils_1.BRANCHPIPE_LAYER);
                if (k == sub_floor.floor_indicator.length - 1 && i == floors.length - 1) {
                    entity_point.blockName = "xsf-2";
                    //添加末端试水装置;
                    {
                        let mdsx_width = this.calculateTextWidth("末端试水装置", 200);
                        let ssf_x_pos = sxf_pos.x + 4800;
                        this.drawAnnotationbyPos("末端试水装置", new ltpoint_1.LTPoint(ssf_x_pos - mdsx_width * 0.5, sxf_pos.y - 800), 0, sprinklerutils_1.PIPDIAMETER_ANNO_LAYER, 200);
                        this.setLastFetureTag(riser_tag);
                    }
                }
                else {
                    entity_point.blockName = "xsf-1";
                    //添加试水阀;
                    {
                        let ssf_x_pos = sxf_pos.x + 4800;
                        let ssf_v_line = new ltentity_1.LTLine(new ltpoint_1.LTPoint(ssf_x_pos, sxf_pos.y - 276), new ltpoint_1.LTPoint(ssf_x_pos - 450, sxf_pos.y - 1154));
                        ssf_v_line.setLayerName(sprinklerutils_1.AUTOPIPE_TEXT_LAYER);
                        this.drawMap(ssf_v_line, riser_tag);
                        let ssf_dn_width = this.calculateTextWidth("试水阀", 200);
                        let ssf_hor_line = new ltentity_1.LTLine(new ltpoint_1.LTPoint(ssf_x_pos - 450 - ssf_dn_width, sxf_pos.y - 1154), new ltpoint_1.LTPoint(ssf_x_pos - 450, sxf_pos.y - 1154));
                        ssf_hor_line.setLayerName(sprinklerutils_1.AUTOPIPE_TEXT_LAYER);
                        this.drawMap(ssf_hor_line, riser_tag);
                        let ssf_an_pos = new ltpoint_1.LTPoint(ssf_x_pos - 450 - ssf_dn_width, sxf_pos.y - 1100);
                        this.drawAnnotationbyPos("试水阀", ssf_an_pos, 0, sprinklerutils_1.AUTOPIPE_TEXT_LAYER, 200);
                        this.setLastFetureTag(riser_tag);
                    }
                }
                //添加泄水阀标注;
                {
                    let xsf_x_pos = sxf_pos.x + 1200;
                    let xsf_v_line = new ltentity_1.LTLine(new ltpoint_1.LTPoint(xsf_x_pos, sxf_pos.y - 600), new ltpoint_1.LTPoint(xsf_x_pos, sxf_pos.y - 1154));
                    xsf_v_line.setLayerName(sprinklerutils_1.AUTOPIPE_TEXT_LAYER);
                    this.drawMap(xsf_v_line, riser_tag);
                    //找到末端的试水装置;
                    let tail_feature = this.findTailByName(riser_name);
                    let tail_string = "";
                    if (tail_feature !== null) {
                        tail_string = this.getPipeDiamter(tail_feature, FlagType.FlagTail);
                    }
                    //强制设置;
                    tail_string = 'DN50';
                    let xsf_dn_width = this.calculateTextWidth(`泄水阀${tail_string}`, 200);
                    let xsf_hor_line = new ltentity_1.LTLine(new ltpoint_1.LTPoint(xsf_x_pos, sxf_pos.y - 1154), new ltpoint_1.LTPoint(xsf_x_pos + xsf_dn_width, sxf_pos.y - 1154));
                    xsf_hor_line.setLayerName(sprinklerutils_1.AUTOPIPE_TEXT_LAYER);
                    this.drawMap(xsf_hor_line, riser_tag);
                    let xsf_an_pos = new ltpoint_1.LTPoint(xsf_x_pos, sxf_pos.y - 1100);
                    this.drawAnnotationbyPos(`泄水阀${tail_string}`, xsf_an_pos, 0, sprinklerutils_1.AUTOPIPE_TEXT_LAYER, 200);
                    this.setLastFetureTag(riser_tag);
                }
                result_geometris.push(entity_point);
                result_geometris.push(line);
            }
        }
        this.drawMap(result_geometris, riser_tag);
        return result_x_pos;
    }
    //绘制到地图上;
    drawMap(geo, tag = null) {
        if (this._map == null) {
            return;
        }
        let cad_canvas = this._map.canvas;
        if (geo instanceof ltentity_1.LTEntity) {
            let feature = new ltfeature_1.LTFeature({
                geometry: geo.clone(),
                layer: this._map.getLayerByName(geo.getLayerName())
            });
            feature._xdata.source = tag;
            this._result_features.push(feature);
            return;
        }
        let geos = geo;
        for (let sub_geo of geos) {
            let feature = new ltfeature_1.LTFeature({
                geometry: sub_geo.clone(),
                layer: this._map.getLayerByName(sub_geo.getLayerName())
            });
            feature._xdata.source = tag;
            this._result_features.push(feature);
        }
    }
    setWidth(width) {
        this._scale_factor = width / 84100.0;
    }
    appendFeatures(use_text_scale = true) {
        if (this._map == null) {
            return false;
        }
        if (this._result_features.length == 0) {
            this._map.printMessage("没有生成任何图形");
            return false;
        }
        //对所有图形做一个缩放处理;
        let scale_factor = this._scale_factor;
        let text_factor = scale_factor - Math.floor(this._scale_factor);
        if (text_factor < 0.3) {
            text_factor = Math.floor(scale_factor);
        }
        else if (text_factor < 0.75) {
            text_factor = Math.floor(scale_factor) + 0.5;
        }
        else {
            text_factor = Math.floor(scale_factor) + 1;
        }
        for (let feature of this._result_features) {
            let geo = feature.getGeometry();
            let temp_scale = scale_factor;
            if (geo instanceof ltentity_2.LTText) {
                if (use_text_scale) {
                    temp_scale = text_factor;
                }
            }
            feature.getGeometry().scale(new ltpoint_1.LTPoint(temp_scale, temp_scale), this.insertPoint);
        }
        this._map.addFeature(this._result_features);
        this._map.printMessage(`共生成${this._result_features.length}个图形`);
        return true;
    }
}
function* designGraphcisCommand(map, param_map) {
    let result_box = null;
    let is_house = param_map.type;
    let query_box = null;
    if (is_house) {
        commandfactory_1.CommandFactory.getInstance().getPoint(map, "选择数据范围的起点:");
        let input_first = yield;
        if (input_first.done == commandfactory_1.CommandStatus.eCancel || input_first.done == commandfactory_1.CommandStatus.eDone) {
            return { value: null, done: input_first.done };
        }
        let first_point = input_first.value;
        function drawRectCallback(mouse) {
            let query_box2 = ltpoint_1.RBox.formVectors(first_point, mouse.mapPos);
            if (query_box != null) {
                drawutils_1.DrawUtilImpl.destroyEntity(map, query_box);
            }
            query_box = ltutils_1.LTUtils.boxToPolyline(query_box2);
            drawutils_1.DrawUtilImpl.trackEntity(map, query_box);
        }
        commandfactory_1.CommandFactory.getInstance().getPoint(map, "指定范围的对角点:", drawRectCallback);
        let input_second = yield;
        if (input_second.done == commandfactory_1.CommandStatus.eCancel || input_second.done == commandfactory_1.CommandStatus.eDone) {
            if (query_box) {
                drawutils_1.DrawUtilImpl.destroyEntity(map, query_box);
            }
            return { value: null, done: input_second.done };
        }
        result_box = ltpoint_1.RBox.formVectors(first_point, input_second.value);
    }
    commandfactory_1.CommandFactory.getInstance().getPoint(map, "指定原型图插入点:");
    let input_insert = yield;
    if (query_box !== null) {
        drawutils_1.DrawUtilImpl.destroyEntity(map, query_box);
    }
    if (input_insert.done == commandfactory_1.CommandStatus.eCancel || input_insert.done == commandfactory_1.CommandStatus.eDone) {
        return { value: null, done: input_insert.done };
    }
    let insert_point = input_insert.value;
    let param_insert = {
        blockName: param_map.blockName,
        onlyone: true,
        blockScale: param_map.blockScale,
        layername: 'D-层框',
    };
    let insert_command_name = JSON.stringify({ commandName: "insert", paramList: [`${insert_point.x},${insert_point.y}`] });
    commandfactory_1.CommandFactory.getInstance().execYieldCommand(insert_command_name, map, param_insert);
    let insert_command = yield;
    if (insert_command.done == commandfactory_1.CommandStatus.eCancel || insert_command.done == commandfactory_1.CommandStatus.eDone) {
        return { value: null, done: insert_command.done };
    }
    let block_feature = insert_command.value[0];
    let bounding_box = block_feature.getGeometry().getBoundingBox();
    let floor_num = param_map.floorNum || 1;
    let desgin = new DesignGraphcisCommandImpl(map, false);
    desgin.setWidth(bounding_box.getWidth());
    if (is_house) {
        desgin.generateHouse(result_box, insert_point, floor_num);
        desgin.appendFeatures(false);
    }
    else {
        block_feature._xdata.framebox = param_map.box_list;
        desgin.generate(insert_point, param_map.box_list);
        desgin.appendFeatures();
    }
    return { value: null, done: commandfactory_1.CommandStatus.eDone };
}
function* linkageUpdate(map, param_map) {
    const callback_filter = (feature) => {
        if (feature._xdata === null || feature._xdata.framebox === undefined || feature._xdata.framebox === null) {
            return false;
        }
        return true;
    };
    let frame_list = map.selectFeatures(null, callback_filter);
    for (let frame of frame_list) {
        let frame_boxs = frame._xdata.framebox;
        let new_boxs = [];
        for (let i = 0; i < frame_boxs.length; ++i) {
            let sub_box = frame_boxs[i];
            let rb = sub_box.box;
            let p1 = new ltpoint_1.LTPoint(rb.c1.x, rb.c1.y);
            let p2 = new ltpoint_1.LTPoint(rb.c2.x, rb.c2.y);
            let box = ltpoint_1.RBox.formVectors(p1, p2);
            new_boxs.push({ index: sub_box.index, box: box, height: sub_box.height });
        }
        //获取插入点;
        let bounding_box = frame.getGeometry().getBoundingBox();
        let insert_point = bounding_box.getMinimum();
        let desgin = new DesignGraphcisCommandImpl(map, true);
        //清理这个范围内的立管相关数据;
        let need_clear_features = map.selectFeatures(bounding_box, (feature) => {
            if (feature._xdata === null || feature._xdata.source === null) {
                return false;
            }
            if (feature._xdata.source == "autoDesignRiser") {
                return true;
            }
            return false;
        });
        map.removeFeatures(need_clear_features);
        desgin.setWidth(bounding_box.getWidth());
        desgin.generate(insert_point, new_boxs);
        desgin.appendFeatures();
    }
    return { value: null, done: commandfactory_1.CommandStatus.eDone };
}
