"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProtectAreaInfoConfig = void 0;
class ProtectAreaInfoConfig {
    static getProtectAreaInfo(sprinklerCategory, isTallRoom, dangerLevel, maxDisBetweenSprinklers, minDisBetweenSprinklers, maxDisWithWall, minDisWithWall) {
        if (sprinklerCategory == "早期抑制快速响应喷头" || sprinklerCategory == "仓库型特殊应用喷头") {
            return {
                maxDisBetweenSprinklers: maxDisBetweenSprinklers,
                minDisBetweenSprinklers: minDisBetweenSprinklers,
                maxDisWithWall: maxDisWithWall,
                minDisWithWall: minDisWithWall,
            };
        }
        else {
            if (isTallRoom === true) { //高大空间
                return {
                    maxDisBetweenSprinklers: 3.0,
                    minDisBetweenSprinklers: 1.8,
                    maxDisWithWall: 1.5,
                    minDisWithWall: 0.1,
                };
            }
            else {
                if (sprinklerCategory == "标准覆盖面积洒水喷头") {
                    switch (dangerLevel) {
                        case "轻危险级": {
                            return {
                                maxDisBetweenSprinklers: 4.5,
                                minDisBetweenSprinklers: 1.8,
                                maxDisWithWall: 2.2,
                                minDisWithWall: 0.1,
                            };
                        }
                        case "中危险I级": {
                            return {
                                maxDisBetweenSprinklers: 4.0,
                                minDisBetweenSprinklers: 1.8,
                                maxDisWithWall: 1.8,
                                minDisWithWall: 0.1,
                            };
                        }
                        case "中危险II级": {
                            return {
                                maxDisBetweenSprinklers: 3.6,
                                minDisBetweenSprinklers: 1.8,
                                maxDisWithWall: 1.7,
                                minDisWithWall: 0.1,
                            };
                        }
                        case "严重危险I级":
                        case "严重危险II级":
                        case "仓库危险级I级":
                        case "仓库危险级II级":
                        case "仓库危险级III级-货架储存时":
                        case "仓库危险级III级-堆垛储存时": {
                            return {
                                maxDisBetweenSprinklers: 3.6,
                                minDisBetweenSprinklers: 1.8,
                                maxDisWithWall: 1.5,
                                minDisWithWall: 0.1,
                            };
                        }
                        default:
                            break;
                    }
                }
                else if (sprinklerCategory == "扩大覆盖面积洒水喷头") {
                    switch (dangerLevel) {
                        case "轻危险级": {
                            return {
                                maxDisBetweenSprinklers: 5.4,
                                minDisBetweenSprinklers: 1.8,
                                maxDisWithWall: 2.7,
                                minDisWithWall: 0.1,
                            };
                        }
                        case "中危险I级": {
                            return {
                                maxDisBetweenSprinklers: 4.8,
                                minDisBetweenSprinklers: 1.8,
                                maxDisWithWall: 2.4,
                                minDisWithWall: 0.1,
                            };
                        }
                        case "中危险II级": {
                            return {
                                maxDisBetweenSprinklers: 4.2,
                                minDisBetweenSprinklers: 1.8,
                                maxDisWithWall: 2.1,
                                minDisWithWall: 0.1,
                            };
                        }
                        case "严重危险I级":
                        case "严重危险II级": {
                            return {
                                maxDisBetweenSprinklers: 3.6,
                                minDisBetweenSprinklers: 1.8,
                                maxDisWithWall: 1.8,
                                minDisWithWall: 0.1,
                            };
                        }
                        default:
                            break;
                    }
                }
            }
        }
        throw Error("获取喷头间距信息错误");
    }
    static getSprayRadius(sprinklerCategory, isTallRoom, dangerLevel, maxDisWithWall) {
        if (sprinklerCategory == "早期抑制快速响应喷头" || sprinklerCategory == "仓库型特殊应用喷头") {
            //自定义，直接用与墙体的最大距离作为喷淋半径
            return maxDisWithWall;
        }
        else {
            if (isTallRoom) {
                return 1.5;
            }
            else {
                //return Math.sqrt(this.getProtectArea(sprinklerCategory, dangerLevel) / Math.PI);
                return Math.sqrt(this.getProtectArea(sprinklerCategory, dangerLevel) * 2) / 2;
            }
        }
        //throw Error("获取喷淋半径错误");
    }
    static getProtectArea(sprinklerCategory, dangerLevel) {
        if (sprinklerCategory == "标准覆盖面积洒水喷头") {
            switch (dangerLevel) {
                case "轻危险级": {
                    return 20.0;
                }
                case "中危险I级": {
                    return 12.5;
                }
                case "中危险II级": {
                    return 11.5;
                }
                case "严重危险I级":
                case "严重危险II级":
                case "仓库危险级I级":
                case "仓库危险级II级":
                case "仓库危险级III级-货架储存时":
                case "仓库危险级III级-堆垛储存时": {
                    return 9.0;
                }
                default:
                    break;
            }
        }
        else if (sprinklerCategory == "扩大覆盖面积洒水喷头") {
            switch (dangerLevel) {
                case "轻危险级": {
                    return 29.0;
                }
                case "中危险I级": {
                    return 23.0;
                }
                case "中危险II级": {
                    return 17.5;
                }
                case "严重危险I级":
                case "严重危险II级": {
                    return 13.0;
                }
                default:
                    break;
            }
        }
        throw Error("获取喷淋保护面积错误");
    }
}
exports.ProtectAreaInfoConfig = ProtectAreaInfoConfig;
